<template>
  <div class="flex flex-col lg:flex-row lg:p-8">
    <div class="flex-1 flex flex-col">
      <div class="flex-auto bg-white border border-cc-border m-4">
        <div class="border-b border-cc-border flex px-10 py-6">
          <span
              class="border border-cc-border rounded-full h-8 w-8 flex items-center justify-center font-serif text-xl">1</span>
          <span class="flex items-center justify-center pl-4 font-serif text-xl">Your wall dimensions</span>
        </div>
        <div class="p-10">
          <div>Unit of measurement</div>
          <div class="flex mt-2">
            <div class="border border-cc-border cursor-pointer transition-colors duration-500 p-6"
                 :class="{ 'bg-black text-white': isWallMetric, 'bg-white text-black hover:bg-black hover:text-white': !isWallMetric }"
                 v-on:click="wallIsMetric">Metre
            </div>
            <div class="border border-cc-border cursor-pointer transition-colors duration-500 p-6 ml-4"
                 :class="{ 'bg-black text-white': !isWallMetric, 'bg-white text-black hover:bg-black hover:text-white': isWallMetric }"
                 v-on:click="wallIsImperial">Feet
            </div>
          </div>
          <div class="mt-6">Wall width</div>
          <div class="flex mt-2">
            <input class="max-w-3/4 border border-cc-border focus:border-cc-border-focus outline-none p-6"
                   v-model="wallWidth"/>
            <div class="border border-cc-border p-6 bg-cc-background">{{ wallUnit }}</div>
          </div>
          <div class="mt-6">Wall height</div>
          <div class="flex mt-2">
            <input class="max-w-3/4 border border-cc-border focus:border-cc-border-focus outline-none p-6"
                   v-model="wallHeight"/>
            <div class="border border-cc-border p-6 bg-cc-background">{{ wallUnit }}</div>
          </div>
        </div>
      </div>
      <div class="flex-auto bg-white border border-cc-border m-4">
        <div class="border-b border-cc-border flex px-10 py-6">
          <span
              class="border border-cc-border rounded-full h-8 w-8 flex items-center justify-center font-serif text-xl">2</span>
          <span class="flex items-center justify-center pl-4 font-serif text-xl">Your roll dimensions</span>
        </div>
        <div class="p-10">
          <div>Unit of measurement</div>
          <div class="flex mt-2">
            <div class="border border-cc-border cursor-pointer transition-colors duration-500 p-6"
                 :class="{ 'bg-black text-white': isRollMetric, 'bg-white text-black hover:bg-black hover:text-white': !isRollMetric }"
                 v-on:click="rollIsMetric">Metre
            </div>
            <div class="border border-cc-border cursor-pointer transition-colors duration-500 p-6 ml-4"
                 :class="{ 'bg-black text-white': !isRollMetric, 'bg-white text-black hover:bg-black hover:text-white': isRollMetric }"
                 v-on:click="rollIsImperial">Feet
            </div>
          </div>
          <div class="mt-6">Roll width</div>
          <div class="flex mt-2">
            <input class="max-w-3/4 border border-cc-border focus:border-cc-border-focus outline-none p-6"
                   v-model="rollWidth"/>
            <div class="border border-cc-border p-6 bg-cc-background">{{ rollUnit }}</div>
          </div>
          <div class="mt-6">Roll height</div>
          <div class="flex mt-2">
            <input class="max-w-3/4 border border-cc-border focus:border-cc-border-focus outline-none p-6"
                   v-model="rollHeight"/>
            <div class="border border-cc-border p-6 bg-cc-background">{{ rollUnit }}</div>
          </div>
          <div class="mt-6">Design repeat</div>
          <div class="flex mt-2">
            <input class="max-w-3/4 border border-cc-border focus:border-cc-border-focus outline-none p-6"
                   v-model="rollRepeat"/>
            <div class="border border-cc-border p-6 bg-cc-background">cm</div>
          </div>
          <div
              class="w-48 border border-cc-border cursor-pointer transition-colors duration-500 bg-black hover:bg-opacity-75 text-white p-6 mt-10"
              v-on:click="calculate">Estimate rolls needed
          </div>
          <p class="mt-20">Please note:</p>
          <ul class="list-disc list-inside">
            <li class="mt-4">Cole & Son will not be responsible for overage or shortage based on these calculations.
            </li>
            <li class="mt-4">Different designs will require varying quantities depending on the match type and the size
              of the repeat pattern.
            </li>
            <li class="mt-4">This calculator does not take in to consideration any doors or windows.</li>
            <li class="mt-4">Cole & Son do not quote quantities and recommend you consult your decorator before
              ordering.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="flex-1">
      <div class="sticky top-4 bg-white border border-cc-border m-4 px-4 sm:px-10 py-6">
        <div class="font-serif text-xl">Your results</div>
        <div class="text-base mt-4">You will need approximately:</div>
        <div class="grid grid-cols-2 gap-4 mt-4">
          <div class="bg-cc-background border border-cc-border text-center px-2 sm:px-10 pt-4 sm:pt-10 pb-4">
            <div class="text-3xl sm:text-5xl pb-4 sm:pb-8">{{ resultSquareMetres }}</div>
            <div class="text-xs sm:text-sm">Square metres of wallpaper</div>
          </div>
          <div class="bg-cc-background border border-cc-border text-center px-2 sm:px-10 pt-4 sm:pt-10 pb-4">
            <div class="text-3xl sm:text-5xl pb-4 sm:pb-8">{{ resultRollsRounded }}</div>
            <div class="text-xs sm:text-sm">rolls of wallpaper<br><span v-if="resultRolls">(rounded up from {{ resultRolls }})</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isWallMetric: true,
      wallUnit: 'm',
      wallWidth: 2.5,
      wallHeight: 2.5,
      isRollMetric: true,
      rollUnit: 'm',
      rollWidth: 0.7,
      rollHeight: 10,
      rollRepeat: 1,
      resultSquareMetres: "",
      resultRollsRounded: "",
      resultRolls: "",
    };
  },
  mounted() {
    let roll = {}
    roll.unit = this.$route.query.rollUnit
    roll.width = this.$route.query.rollWidth
    roll.height = this.$route.query.rollHeight
    roll.repeat = this.$route.query.rollRepeat

    if (!roll.unit || !roll.width || !roll.height || !roll.repeat) {
      return
    }

    if (roll.unit !== 'm' && roll.unit !== 'ft') {
      return
    }

    if (isNaN(roll.width) || isNaN(roll.height) || isNaN(roll.repeat)) {
      return
    }

    if (roll.width <= 0 || roll.height <= 0 || roll.repeat < 0) {
      return
    }

    this.rollUnit = roll.unit
    this.rollWidth = roll.width
    this.rollHeight = roll.height
    this.rollRepeat = roll.repeat
    this.isRollMetric = roll.unit === 'm'
  },
  methods: {
    wallIsMetric: function () {
      this.isWallMetric = true
      this.wallUnit = 'm'
    },
    wallIsImperial: function () {
      this.isWallMetric = false
      this.wallUnit = 'ft'
    },
    rollIsMetric: function () {
      this.isRollMetric = true
      this.rollUnit = 'm'
    },
    rollIsImperial: function () {
      this.isRollMetric = false
      this.rollUnit = 'ft'
    },
    calculate: function () {
      // Constants
      let conversionToCm = {'ft': 30.48, 'm': 100};

      // Input fields in cm
      let ww = this.isWallMetric ? this.wallWidth * conversionToCm.m : this.wallWidth * conversionToCm.ft
      let wh = this.isWallMetric ? this.wallHeight * conversionToCm.m : this.wallHeight * conversionToCm.ft
      let rw = this.isRollMetric ? this.rollWidth * conversionToCm.m : this.rollWidth * conversionToCm.ft
      let rh = this.isRollMetric ? this.rollHeight * conversionToCm.m : this.rollHeight * conversionToCm.ft
      let rr = parseFloat(this.rollRepeat)

      // Number of lengths / roll
      let stripsRaw = rh / (wh + rr);
      let strips = stripsRaw < 0 ? Math.ceil(stripsRaw) : Math.floor(stripsRaw);

      // Number of rolls
      let stripWidth = strips * rw;
      let numRolls = Math.round((ww / stripWidth) * 10) / 10;
      if (isNaN(numRolls)) {
        numRolls = 0;
      }

      // Output fields
      if (!isNaN(ww) && !isNaN(wh)) {
        this.resultSquareMetres = (ww * wh / conversionToCm.m / conversionToCm.m).toFixed(1)
        this.resultRolls = numRolls
        this.resultRollsRounded = Math.ceil(numRolls)
      }
    },
  },
};
</script>